<script setup lang="ts">
import { vElementVisibility } from "@vueuse/components";

import Logo from "@/assets/logo.svg";

class C {
  id: number;
  text: string;
  style: object;
  constructor(id: number, text: string, style: object = {}) {
    this.id = id;
    this.text = text;
    this.style = style;
  }
  static of(id: number, text: string, style: object = {}) {
    return new C(id, text, style);
  }
}

const texts = [
  {
    width: "min(100%, 320px)",
    height: "min(100%, 320px)",
    fontSize: "initial",
    contents: [Logo],
  },
  {
    fontSize: "min(26vw,500px)",
    contents: [C.of(0, "A"), C.of(2, "S"), C.of(4, "K")],
  },
  {
    fontSize: "min(8.8vw,100px)",
    contents: [
      C.of(0, "A"),
      C.of(1, "rchitect&nbsp"),
      C.of(2, "S"),
      C.of(3, "tudio&nbsp"),
      C.of(4, "K"),
      C.of(5, "im"),
    ],
  },
  {
    fontSize: "min(26vw,500px)",
    contents: [C.of(0, "A"), C.of(2, "S"), C.of(4, "K")],
  },
  {
    fontSize: "min(15vw,150px)",
    contents: [
      C.of(0, "A"),
      C.of(1, "lle"),
      C.of(2, "S"),
      C.of(3, "&nbsp"),
      C.of(4, "K"),
      C.of(5, "lar"),
    ],
  },
  {
    fontSize: "min(26vw,500px)",
    contents: [C.of(0, "A"), C.of(2, "S"), C.of(4, "K")],
  },
  {
    width: "min(100%, 320px)",
    height: "min(100%, 320px)",
    fontSize: "initial",
    contents: [Logo],
  },
];
const idx = ref(0);
const currentTexts = computed(() => texts[idx.value]);
const intervalId = ref();

const startInterval = () => {
  if (intervalId.value) return;
  intervalId.value = setInterval(() => {
    idx.value = (idx.value + 1) % texts.length;
  }, 2500);
};
const stopInterval = () => {
  clearInterval(intervalId.value);
  intervalId.value = undefined;
};

onNuxtReady(() => {
  startInterval();

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      stopInterval();
    } else if (document.visibilityState === "visible") {
      startInterval();
    }
  });
});

onUnmounted(() => {
  stopInterval();
});

const onElementVisibility = (isVisible: boolean) => {
  if (isVisible) {
    startInterval();
  } else {
    clearInterval(intervalId.value);
    intervalId.value = undefined;
  }
};
</script>
<template>
  <TransitionGroup
    v-element-visibility="onElementVisibility"
    class="typo-logo relative flex h-[48vh] w-full items-center justify-center overflow-hidden text-black -md:h-[38vh] -sm:h-[30vh]"
    arial-label="Architect Studio Kim"
    tag="div"
    name="list"
  >
    <component
      v-for="t in currentTexts.contents"
      :is="t.text ? 'span' : t"
      :key="t.text ? t.id : -1"
      :class="{
        ['transition-all duration-[1.5s] ease-[cubic-bezier(1,-0.01,.39,.99)]']:
          ['A', 'S', 'K'].includes(t.text),
      }"
      class="will-change-[opacity,transform]"
      :style="{
        ...t.style,
        fontSize: currentTexts.fontSize,
        width: currentTexts.width,
        height: currentTexts.height,
      }"
      v-html="t.text ? t.text : undefined"
    />
  </TransitionGroup>
</template>

<style scoped>
.list-move {
  transition: all 1s ease-in-out;
}

.list-enter-active {
  transition: all 1s ease-in-out;
}

.list-leave-active {
  position: absolute;
  transition:
    opacity 0.5s ease-in-out,
    transform 1s ease-in-out;
}

.list-enter-from {
  transform: scale(0);
  opacity: 0;
}

.list-enter-to {
  transform: scale(1);
  opacity: 1;
}

.list-leave-from {
  position: absolute;
  transform: scale(1);
  opacity: 1;
}

.list-leave-to {
  position: absolute;
  transform: scale(0);
  opacity: 0;
}
</style>
