<script setup lang="ts">
import { useWindowScroll } from "@vueuse/core";

import Logo from "@/assets/logo.svg";

const props = defineProps({
  animate: {
    type: Boolean,
    default: false,
  },
  isArticle: {
    type: Boolean,
    default: false,
  },
  toProject: {
    type: Boolean,
    default: false,
  },
  toBim: {
    type: Boolean,
    default: false,
  },
  isHome: {
    type: Boolean,
    default: false,
  },
});
const { localeProperties } = useI18n();
const isPageLoaded = ref(false);
const progress = ref(0);
const { x, y } = useWindowScroll();
const { asLink } = usePrismic();

watch(
  () => [x.value, y.value],
  ([_, yVal]) => {
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    progress.value = yVal / height;
  },
);

onNuxtReady(() => {
  isPageLoaded.value = true;
});

const logoLinkObject = computed(() => {
  const homeLink = {
    link_type: "Document",
    type: "home_page",
    lang: localeProperties.value.language,
    id: "home",
  };
  const projectLink = {
    link_type: "Document",
    type: "project_hub",
    lang: localeProperties.value.language,
    id: "project_hub",
  };
  const bimLink = {
    link_type: "Document",
    type: "bim_hub",
    lang: localeProperties.value.language,
    id: "bim_hub",
  };
  if (props.toBim) return bimLink;
  if (props.toProject) return projectLink;
  return homeLink;
});
</script>

<template>
  <header
    class="fixed top-0 z-[999] w-full transition-all duration-300"
    :class="{ ['shadow-2xl']: isPageLoaded && y > 0 }"
  >
    <nav
      class="relative mx-auto flex w-full max-w-[1024px] items-center justify-between gap-10 bg-white px-10 py-8 -md:px-6 -sm:px-4 -sm:py-3"
      :class="{
        ['!justify-end']: isHome,
      }"
    >
      <ClientOnly>
        <NuxtLink
          v-if="!isHome"
          :to="asLink(logoLinkObject)"
          class="ease-[cubic-bezier(0.175, 0.885, 0.32, 1.275)] origin-center object-cover p-4 text-4xl opacity-10 transition-all duration-200 hover:scale-110 -sm:text-2xl"
          :class="[
            { ['scale-y-100 opacity-100']: !animate },
            { ['!scale-y-100 !opacity-100']: isPageLoaded && animate },
            { ['scale-y-0 !opacity-100']: !isPageLoaded && animate },
          ]"
        >
          <Logo class="h-[40px] w-[40px] -sm:h-[30px] -sm:w-[30px]" />
        </NuxtLink>
      </ClientOnly>

      <div class="flex items-center justify-evenly gap-5 -md:gap-3">
        <LanguageSelector />

        <MobileNav />
      </div>

      <ClientOnly>
        <PageScrollProgressBar
          v-if="isArticle"
          :progress="progress"
          class="absolute bottom-1 left-0 right-0 mx-auto w-[95%]"
        />
      </ClientOnly>
    </nav>
  </header>
</template>
